const MyPopState = {
  ERROR: 'error',
  FOLLOW: 'follow',
  UNFOLLOW: 'unfollow',
};

export default class BrockmanMyPop {
  constructor(args) {
    this.site = args.site;
    this.loggedIn = args.loggedIn;
    this.loginEndpoint = args.loginURL;
    this.interestsStorage = args.interests_storage;
    this.loc = args.loc || {
      error: 'Error',
    };
    this.maxPopupOpens = args.maxPopupOpens || 1;

    this.followButtonsByUUID = {};
    this.popup = null;
    this.popupOpens = 0;

    this.run();
  }

  run() {
    this.loadForElement(document.body);
    this.updateAllButtonStates();
  }

  redirectToLogin() {
    const url = new URL(this.loginEndpoint, window.location.origin);
    window.location.href = url;
  }

  loadForElement(target) {
    const followButtons = target.querySelectorAll('.mypop-button:not([data-init])');
    this.registerFollowButtons(followButtons);
  }

  updateAllButtonStates() {
    this.interestsStorage.getFollows().then((uuids) => this.setFollowButtonStateByUUID(uuids, true));
  }

  registerFollowButtons(buttons) {
    buttons.forEach((button) => {
      const { uuid } = button.dataset;

      if (uuid in this.followButtonsByUUID) this.followButtonsByUUID[uuid].push(button);
      else this.followButtonsByUUID[uuid] = [button];

      button.addEventListener('click', () => this.clickFollowButton(button));
      button.dataset.init = true;

      // Prevent button changing size when toggled
      button.style.minWidth = `${button.getBoundingClientRect().width}px`;
    });
  }

  setFollowButtonState(button, state) {
    const { ariaFollowText, ariaUnfollowText, followText, unfollowText } = button.dataset;

    button.dataset.state = state;

    switch (state) {
      case MyPopState.FOLLOW:
        this.updateButtonText(button, followText, ariaFollowText);
        button.classList.remove('outline');
        break;

      case MyPopState.UNFOLLOW:
        this.updateButtonText(button, unfollowText, ariaUnfollowText);
        button.classList.add('outline');
        break;

      case MyPopState.ERROR:
      default:
        this.updateButtonText(button, this.loc.error, ariaFollowText);
        break;
    }
  }

  updateButtonText(button, text, label) {
    const [parsedText, parsedLabel] = [text, label].map(this.parseJSON);
    button.innerHTML = parsedText;
    button.ariaLabel = parsedLabel;
    button.title = parsedLabel;
  }

  parseJSON(json) {
    return JSON.parse(`"${json}"`);
  }

  setFollowButtonStateByUUID(uuids, isFollowing) {
    uuids.forEach((uuid) => {
      const followButtons = this.followButtonsByUUID[uuid] || [];
      const state = isFollowing ? MyPopState.UNFOLLOW : MyPopState.FOLLOW;
      followButtons.forEach((button) => this.setFollowButtonState(button, state));
    });
  }

  clickFollowButton(button) {
    if (!this.loggedIn && this.withForceLogin(button)) {
      this.redirectToLogin();
      return;
    }

    const { name } = button;

    switch (button.dataset.state) {
      case MyPopState.UNFOLLOW:
        this.sendAnalytics(MyPopState.UNFOLLOW, name);
        this.unfollow(button);
        break;

      case MyPopState.FOLLOW:
      case MyPopState.ERROR:
      default:
        this.sendAnalytics(MyPopState.FOLLOW, name);
        this.follow(button);
        break;
    }
  }

  withForceLogin(button) {
    return button && button.dataset.forceLogin === 'true';
  }

  follow(button) {
    const { uuid } = button.dataset;
    this.interestsStorage
      .followUUIDs([uuid], this.withPopup(button))
      .then((data) => {
        const { markup, uuids } = data;
        if (markup) this.openPopup(markup);
        return uuids || data;
      })
      .then((followed) => this.setFollowButtonStateByUUID(followed, true))
      .catch(() => this.setFollowButtonState(button, MyPopState.ERROR));

    window.sendZDAnalyticsEvent(
      'follow',
      'User Tools',
      'Following',
      'Follow Button',
    );
  }

  unfollow(button) {
    const { uuid } = button.dataset;
    this.interestsStorage
      .unfollowUUID(uuid)
      .then((unfollowed) => this.setFollowButtonStateByUUID([unfollowed], false))
      .catch(() => this.setFollowButtonState(button, MyPopState.ERROR));
  }

  withPopup(button) {
    return button && button.dataset.popup === 'true' && this.popupOpens < this.maxPopupOpens;
  }

  openPopup(markup) {
    if (!markup) return;

    this.popupOpens += 1;

    // Render popup elements
    this.openLightbox();
    this.popup = document.createElement('div');
    this.popup.classList.add('mypop-popup');
    this.popup.innerHTML = markup;
    document.body.append(this.popup);
    this.showPopup();

    // Register popup button events
    const followButtons = this.popup.querySelectorAll('.mypop-button');
    this.registerFollowButtons(followButtons);

    const doneButton = this.popup.querySelector('.button--done');
    if (doneButton)
      doneButton.addEventListener('click', () => {
        this.closePopup();
        this.closeLightbox();
      });
  }

  showPopup() {
    // Delay adding class until next render so can animate with CSS transitions
    setTimeout(() =>
      window.requestAnimationFrame(() => {
        if (this.popup) this.popup.classList.add('visible');
      }),
    );
  }

  closePopup() {
    if (this.popup) this.popup.remove();
  }

  openLightbox() {
    if (!('brockmanLightbox' in window)) return;
    const closeCallback = () => this.closePopup();
    window.brockmanLightbox.open(closeCallback);
    const { lightbox } = window.brockmanLightbox;
    if (lightbox) lightbox.innerHTML = '';
  }

  closeLightbox() {
    if (!('brockmanLightbox' in window)) return;
    window.brockmanLightbox.close();
  }

  sendAnalytics(action, eventName) {
    this.sendToPermutive(action, eventName);
  }

  sendToPermutive(action, value) {
    if (window.permutive) {
      window.permutive.track('UserEngagement', {
        action,
        value,
      });
    }
  }
}
